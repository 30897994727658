<template>
  <div class="filter">
    <div class="items">
      <a
        class="item"
        :class="{ active: selected === 'all' }"
        @click="selected = 'all'"
      >
        <div class="name">All</div>
      </a>
      <a
        class="item"
        :class="{ active: selected === 'showrooms' }"
        @click="selected = 'showrooms'"
      >
        <div class="name">Cane-line Showrooms</div>
      </a>

      <a
        class="item"
        v-for="item in types"
        :class="{ active: selected === item.id }"
        @click="selected = item.id"
      >
        <div class="name">{{ item.name }}</div>
      </a>
    </div>
  </div>
</template>

<script>
import { uniqBy } from "lodash";

export default {
  props: {
    type: { type: [String, Number], required: true },
    items: { type: Array, required: true },
  },
  computed: {
    selected: {
      get() {
        return this.type;
      },
      set(type) {
        this.$emit("update:type", type);
        this.$emit("resetPopup");
      },
    },
    types() {
      const types = this.items.reduce(
        (array, item) => [...array, ...item.types],
        []
      );
      return uniqBy(types, "id");
    },
  },
};
</script>

<style lang="scss" scoped>
.filter {
  font-size: 0.8em;

  @media screen and (max-width: 1024px) {
    display: none;
  }

  .items {
    .item {
      user-select: none;
      display: flex;
      align-items: center;
      padding: 1.25em;
      transition: cubic(background-color, 0.1s);

      &.active {
        background: $blue2;
      }
    }
  }
}
</style>
