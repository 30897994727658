var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filter" }, [
    _c(
      "div",
      { staticClass: "items" },
      [
        _c(
          "a",
          {
            staticClass: "item",
            class: { active: _vm.selected === "all" },
            on: {
              click: function($event) {
                _vm.selected = "all"
              }
            }
          },
          [_c("div", { staticClass: "name" }, [_vm._v("All")])]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "item",
            class: { active: _vm.selected === "showrooms" },
            on: {
              click: function($event) {
                _vm.selected = "showrooms"
              }
            }
          },
          [_c("div", { staticClass: "name" }, [_vm._v("Cane-line Showrooms")])]
        ),
        _vm._v(" "),
        _vm._l(_vm.types, function(item) {
          return _c(
            "a",
            {
              staticClass: "item",
              class: { active: _vm.selected === item.id },
              on: {
                click: function($event) {
                  _vm.selected = item.id
                }
              }
            },
            [_c("div", { staticClass: "name" }, [_vm._v(_vm._s(item.name))])]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }