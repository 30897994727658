<template>
	<div class="search">
		<GmapAutocomplete
			v-bind="options"
			@place_changed="search"
		/>
	</div>
</template>

<script>
export default {
	data() {
		return {
			options: {
				placeholder: "Find a store...",
				selectFirstOnEnter: true,
				class: "input"
			}
		}
	},
	methods: {
		search(place) {
			this.$emit("search", place);
		}
	}
};
</script>

<style lang="scss" scoped>
.search {
	border-bottom: 1px solid #d8dfe7;

	@media screen and (max-width: 1024px) {
		border-bottom: 0;
	}

	.input {
		outline: none;
		width: 100%;
		height: 3.5em;
		padding: 0 1em;
		border: 0px;
		font-size: 0.9em;
	}
}
</style>
