var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.config.local
    ? _c("div", { staticClass: "embed" }, [
        _c("div", { staticClass: "wrap" }, [
          _vm._v("\n\t\tInsert script where the map should be shown"),
          _c("br"),
          _vm._v(" "),
          _c("code", [
            _vm._v(
              '<script data-name="' +
                _vm._s(_vm.config.name) +
                '" data-zoom="4" data-lat="35" data-lng="-97" src="' +
                _vm._s(_vm.src) +
                '"></script>'
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }