var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "GmapMarker",
    _vm._b(
      { on: { click: _vm.toggle } },
      "GmapMarker",
      { position: _vm.position, icon: _vm.icon, clickable: true },
      false
    ),
    [
      _vm.popup === _vm.id
        ? _c("GmapInfoWindow", [
            _c("div", { staticClass: "popup" }, [
              _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
              _vm._v(" "),
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "location" }, [
                  _c("div", { staticClass: "location1" }, [
                    _vm._v(_vm._s(_vm.location1))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "location2" }, [
                    _vm._v(_vm._s(_vm.location2))
                  ])
                ]),
                _vm._v(" "),
                _vm.phone
                  ? _c("div", { staticClass: "phone" }, [
                      _vm._v("\n          Phone: "),
                      _c("a", { attrs: { href: _vm.phoneLink } }, [
                        _vm._v(_vm._s(_vm.phone))
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.website
                  ? _c("div", { staticClass: "website" }, [
                      _vm._v("\n          Website: "),
                      _c(
                        "a",
                        { attrs: { href: _vm.websiteLink, target: "_blank" } },
                        [_vm._v(_vm._s(_vm.website))]
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }