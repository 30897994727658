import "@babel/polyfill";
import Vue from "vue";

import "@/modules/maps";
import axios from "./modules/axios";
import config from "./config";
import app from "./app.vue";

Vue.prototype.$http = axios;

try {
	// add element after script
	if (!config.script) throw `No script exist with data-name="${config.name}"`;

	config.script.insertAdjacentHTML("afterend", `<div id="${config.name}"></div>`);

	new Vue({
		el: `#${config.name}`,
		render: h => h(app)
	});

} catch(err) {
	console.error(err);
}
