<template>
	<div class="app">
		<vMap v-bind="{items}"/>
		<vEmbed/>
	</div>
</template>

<script>
import vMap from "./components/map.vue";
import vEmbed from "./components/embed.vue";

export default {
	components: {vEmbed, vMap},
	data() {
		return {
			items: []
		}
	},
	methods: {
		async getData() {
			const {data: {data}} = await this.$http.get("retailers", {params: {count: 10000, include: ["types"]}});
			this.items = data.filter(x => x.longitude && x.latitude);
		}
	},
	async created() {
		await this.getData();
	}
};
</script>

<style lang="scss" scoped>
.app {
	font-size: 16px;

	/deep/ {
		* {
			font-family: $font1;
			box-sizing: border-box;
		}

		img {
			max-width: 100%;
		}

		a {
			cursor: pointer;
			color: $black1;
			text-decoration: none;
		}

		input {
			font-family: $font1 !important;
		}
	}
}
</style>
