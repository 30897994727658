var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search" },
    [
      _c(
        "GmapAutocomplete",
        _vm._b(
          { on: { place_changed: _vm.search } },
          "GmapAutocomplete",
          _vm.options,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }