export default {
	name: "sikane-map",

	get script() {
		return document.querySelector(`script[data-name='${this.name}']`);
	},

	get settings() {
		return {
			lng: Number(this.script.getAttribute("data-lng") || "-97.380979"),
			zoom: Number(this.script.getAttribute("data-zoom") || "4"),
			lat: Number(this.script.getAttribute("data-lat") || "35")
		}
	},

	get local() {
		return window.location.hostname === this.hostname;
	},

	get hostname() {
		const el = document.createElement("a");
		el.href = this.script.src;
		return el.hostname;
	}
};
