var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "map" }, [
    _c(
      "div",
      { staticClass: "options cleanslate" },
      [
        _c("transition", { attrs: { name: "wrap" } }, [
          _vm.loaded
            ? _c(
                "div",
                { staticClass: "wrap" },
                [
                  _c("vSearch", { on: { search: _vm.search } }),
                  _vm._v(" "),
                  _c(
                    "vFilter",
                    _vm._b(
                      {
                        attrs: { type: _vm.type },
                        on: {
                          "update:type": function($event) {
                            _vm.type = $event
                          },
                          resetPopup: function($event) {
                            _vm.popup = null
                          }
                        }
                      },
                      "vFilter",
                      { items: _vm.items },
                      false
                    )
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "gmap" },
      [
        _c(
          "GmapMap",
          _vm._b(
            {
              ref: "map",
              staticStyle: { width: "100%", height: "600px" },
              attrs: {
                center: { lat: _vm.settings.lat, lng: _vm.settings.lng },
                "map-type-id": "roadmap",
                zoom: _vm.settings.zoom
              }
            },
            "GmapMap",
            { options: _vm.options },
            false
          ),
          [
            _c(
              "GmapCluster",
              { attrs: { zoomOnClick: true } },
              _vm._l(_vm.itemsFilter, function(item) {
                return _c(
                  "vMarker",
                  _vm._b(
                    {
                      key: item.id,
                      attrs: { popup: _vm.popup },
                      on: {
                        "update:popup": function($event) {
                          _vm.popup = $event
                        }
                      }
                    },
                    "vMarker",
                    item,
                    false
                  )
                )
              })
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }