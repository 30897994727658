<template>
	<div class="embed" v-if="config.local">
		<div class="wrap">
			Insert script where the map should be shown<br>
			<code>&lt;script data-name="{{config.name}}" data-zoom="4" data-lat="35" data-lng="-97" src="{{src}}"&gt;&lt;/script&gt;</code>
		</div>
	</div>
</template>

<script>
import config from "@/config";

export default {
	computed: {
		config: (t) => config,
		src: (t) => `https://${t.config.hostname}/app.js`
	}
};
</script>

<style lang="scss" scoped>
.embed {
	.wrap {
		margin-top: 1em;
		font-size: 0.9em;
		line-height: 1.5em;

		code {
			background-color: $blue2;
			padding: 0.2em 0.3em;
			border-radius: 2px;
		}
	}
}
</style>
